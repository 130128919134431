import { loadWizard } from './utilts'

const START_TIME: number = performance.now()

loadWizard()
  .then(() => console.log('RLE snippet is running..'))
  .catch((error) => console.error(`Please check errors ${error}`))
  .finally(() => {
    const END_TIME = performance.now()
    console.log(`Time spent on executing the configuration script: ${END_TIME - START_TIME} milliseconds`)
  })
