export enum ErrorLogSource {
  RECEIVE_LEAD = 'RECEIVE_LEAD',
  ENRICH_LEAD = 'ENRICH_LEAD',
  VALUATION = 'VALUATION',
  PHONE_NUMBER_FORMAT = 'PHONE_NUMBER_FORMAT',
  GAC_LOCATION = 'GAC_LOCATION',
  GAC_ENTITY = 'GAC_ENTITY',
  MAPS_PLACE_PREDICTIONS = 'MAPS_PLACE_PREDICTIONS',
  MAPS_PLACE_DETAIL = 'MAPS_PLACE_DETAIL',
  ERROR_BOUNDARY = 'ERROR_BOUNDARY',
  VALUATION_PDF = 'VALUATION_PDF',
  USER_INFO = 'USER_INFO',
  WIZARD_CONFIGURATOR = 'WIZARD_CONFIGURATOR',
}

export interface ErrorLogRequest {
  message: string
  source: ErrorLogSource
}
