import { ConfigFromServer } from '../@types/config'
import { sendErrorLog } from '../clients/logClient'
import { ErrorLogSource } from '../@types/errorLog'

const WIZARD_JAVASCRIPT_BUNDLE_URL = `${process.env.WIZARD_URL}/frontend/wizard.js`
const WIZARD_SCOPED_CSS_URL = `${process.env.WIZARD_URL}/frontend/static/css/scoped-is24-core.css`
const WIZARD_CONFIGURATOR_CSS_URL = `${process.env.WIZARD_URL}/frontend/static/css/wizard-configurator.css`
const RLE_INTEGRATION_ID = 'rle-wizard'
const COOPERATION_ID_ATTRIBUTE = 'cooperation-id'

const getCooperationIdFromDiv = (): Promise<string> =>
  new Promise((resolve, reject) => {
    const rleWizardDiv: HTMLElement | null = document.getElementById(RLE_INTEGRATION_ID)
    if (rleWizardDiv) {
      const cooperationId = rleWizardDiv.getAttribute(COOPERATION_ID_ATTRIBUTE)
      if (cooperationId) {
        resolve(cooperationId)
      } else {
        reject('cooperation id is not present')
      }
    } else {
      console.log('RLE is not integrated')
      return reject(`Cannot find HTML element with id ${RLE_INTEGRATION_ID}`)
    }
  })

const addCssToHtml = (url: string): Promise<boolean> =>
  new Promise((resolve) => {
    const htmlLinkElement = document.createElement('link')
    htmlLinkElement.setAttribute('rel', 'stylesheet')
    htmlLinkElement.setAttribute('href', url)
    htmlLinkElement.setAttribute('type', 'text/css')
    htmlLinkElement.setAttribute('media', 'all')

    htmlLinkElement.addEventListener('load', () => {
      console.log('wizard scoped css bundle is loaded properly')
    })

    htmlLinkElement.addEventListener('error', (ev: ErrorEvent) => {
      console.log(`There was a error while loading wizard scoped css bundle ${ev}`)
    })

    document.head.appendChild(htmlLinkElement)
    resolve(true)
  })

const fetchConfigurationFor = (id: string): Promise<ConfigFromServer> =>
  fetch(`${process.env.WIZARD_URL}/wizard-configurator/configurations/${id}_PUBLISHED.json`)
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      throw new Error(`${response.status}`)
    })
    .catch((error) => {
      const errorMessage = `Error while fetching the configuration from the sever for ${id}, message: ${error.message}`
      sendErrorLog({
        message: errorMessage,
        source: ErrorLogSource.WIZARD_CONFIGURATOR,
      })
      throw new Error(errorMessage)
    })

const addWizardJavascriptBundle = (url: string = WIZARD_JAVASCRIPT_BUNDLE_URL, isAsync = false): Promise<boolean> =>
  new Promise((resolve) => {
    const htmlScriptElement = document.createElement('script')
    htmlScriptElement.setAttribute('src', url)
    htmlScriptElement.setAttribute('type', 'text/javascript')
    htmlScriptElement.setAttribute('async', String(isAsync))

    htmlScriptElement.addEventListener('load', () => {
      console.log('wizard javascript bundle is loaded properly')
    })

    htmlScriptElement.addEventListener('error', (ev) => {
      console.log(`There was a error while loading wizard javascript bundle ${ev}`)
    })

    document.body.appendChild(htmlScriptElement)
    resolve(true)
  })

const setConfiguration = (configuration: ConfigFromServer): Promise<boolean> =>
  new Promise((resolve) => {
    console.log('Setting configuration in window object')
    window.IS24 = window.IS24 || {}
    window.IS24.RLE = window.IS24.RLE || {}
    window.IS24.RLE.configuration = configuration
    resolve(true)
  })

const loadWizard = async (): Promise<void> => {
  addCssToHtml(WIZARD_CONFIGURATOR_CSS_URL)
    .then(() => addCssToHtml(WIZARD_SCOPED_CSS_URL))
    .then(() => console.log('added scoped CSS HTML tag'))
    .catch(console.error)

  getCooperationIdFromDiv()
    .then((cooperationId: string) => {
      return fetchConfigurationFor(cooperationId)
    })
    .then((configuration: ConfigFromServer) => {
      return setConfiguration(configuration)
    })
    .catch((err) => {
      console.log(`There was error while loading the configuration ${err}`)
      console.log('Will load RLE wizard without configuration')
      throw err
    })
    .finally(() => {
      addWizardJavascriptBundle()
        .then(() => console.log('added wizard javascript bundle to HTML tag'))
        .catch(console.error)
    })
}

export {
  getCooperationIdFromDiv,
  addCssToHtml,
  fetchConfigurationFor,
  addWizardJavascriptBundle,
  setConfiguration,
  loadWizard,
}
