import { ErrorLogRequest } from '../@types/errorLog'

export const sendErrorLog = (request: ErrorLogRequest): void => {
  fetch(`${process.env.WIZARD_URL}/api/log/ui-error`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      message: request.message,
      source: request.source,
      userAgent: window.navigator.userAgent,
      url: window.location.href,
      plugins: JSON.stringify(navigator.plugins),
    }),
  })
    .then((response) => {
      if (!response.ok) throw new Error(`Error while sending error log, status: ${response.status}`)
      return Promise.resolve()
    })
    .catch((error) => {
      console.error(error)
    })
}
